// https://github.com/yyx990803/vue-lit
// Thomas: removed shadow

import 'core-js'
import 'regenerator-runtime/runtime'
import { render } from 'lit-html'
import { shallowReactive, effect } from '@vue/reactivity'

let currentInstance

export function def(name, config, factory) {
  customElements.define(
    name,
    class extends HTMLElement {
      static get observedAttributes() {
        return config.props
      }
      constructor() {
        super()
        const props = (this._props = shallowReactive({}))
        currentInstance = this
        const template = factory.call(this, props)
        currentInstance = null
        this._bm && this._bm.forEach(cb => cb())
        const root = config.shadow ? this.attachShadow({ mode: 'closed' }) : null
        let isMounted = false
        effect(() => {
          if (!isMounted) {
            this._bu && this._bu.forEach(cb => cb())
          }
          render(template(), root ? root : this)
          if (isMounted) {
            this._u && this._u.forEach(cb => cb())
            if (root) {
              const so = root.querySelector('.search-options')
              let sh = document.querySelector('sticky-header')
              if(!sh) {
                sh = document.getElementById('StickNavWrapper')
              }

              if (so) {
                const observer = new IntersectionObserver(
                  ([e]) => {
                    if (!e.isIntersecting) so.setAttribute(`stuck`, true)
                    else so.removeAttribute('stuck')
                    if (e.isIntersecting && so.hasAttribute('lock')) so.setAttribute(`stuck`, true)
                  },
                  { threshold: [1] }
                )
                observer.observe(so)
                document.body.addEventListener('wheel', () => {
                  if (sh && so.getBoundingClientRect().top > sh.getBoundingClientRect().height) so.removeAttribute('stuck')
                })
              }
              if (sh) {
                const observer = new IntersectionObserver(
                  ([e]) => {
                    if (e.isIntersecting && so?.hasAttribute('stuck')) {
                      so.setAttribute(`style`, `top:${e.boundingClientRect.height}px;`)
                      so.setAttribute(`lock`, true)
                      so.setAttribute(`stuck`, true)
                    } else {
                      so?.removeAttribute('style')
                      so?.removeAttribute(`lock`, true)
                    }
                  },
                  { threshold: [1] }
                )
                observer.observe(sh)
              }
            }
          } else {
            isMounted = true
          }
        })
      }
      connectedCallback() {
        this._m && this._m.forEach(cb => cb())
      }
      disconnectedCallback() {
        this._um && this._um.forEach(cb => cb())
      }
      attributeChangedCallback(name, oldValue, newValue) {
        this._props[name] = newValue
      }
    }
  )
}

function createLifecycleMethod(name) {
  return cb => {
    if (currentInstance) {
      ;(currentInstance[name] || (currentInstance[name] = [])).push(cb)
    }
  }
}

export const onBeforeMount = createLifecycleMethod('_bm')
export const onMounted = createLifecycleMethod('_m')
export const onBeforeUpdate = createLifecycleMethod('_bu')
export const onUpdated = createLifecycleMethod('_u')
export const onUnmounted = createLifecycleMethod('_um')

export * from 'lit-html'
export * from '@vue/reactivity'
