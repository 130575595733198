import { def, reactive as ref, html } from './lit'
import './default'

const script = document.currentScript
if(script.hasAttribute('data-token')) {
  document.body.append(document.createElement('seeker-parts-finder-root'))
}

def('seeker-parts-finder-root', { props: ['stylesheet'] }, (props) => () => html`
  <seeker-parts-finder stylesheet="${props.stylesheet}"/>
`)

